<template lang="html">
    <div class="WatUXQuanti">
        <iframe 
            style="border: 1px solid rgba(0, 0, 0, 0.1);" 
            height="100%"
            width="100%"
            frameborder="0"
            :src="'https://www.figma.com/embed?embed_host=share&url=' + (isMobile ? data.mobileStartUrl : data.desktopStartUrl)" 
            allowfullscreen></iframe>
        <div class="WatUXQuanti--Info">
            <div class="WatUXQuanti--Instructions" v-on:click="openInformationModal()">
                <i class="fas fa-info-circle"></i>
                <span>{{$t('watux_quanti_information')}}</span>
            </div>
            <button v-on:click="goToQuestions()">{{$t('watux_quanti_finish_action')}}</button>
        </div>
        <div class="WatUXQuanti--Modal" v-show="showModal">
             <div class="WatUXQuanti--ModalContent">
                <div class="WatUXQuanti--ModalHeader">
                    <h1>{{$t('watux_quanti_information')}}</h1>
                    <i class="fas fa-times" v-on:click="openInformationModal()"></i>
                </div>
                <p v-html="infoText"></p>
                <button v-on:click="openInformationModal()">{{$t('watux_quanti_accept_button')}}</button>  
             </div>
        </div>
    </div>
</template>
<script>
    import Swal from 'sweetalert2'
    export default {
    name: 'WatUXQuanti',
    props:{
        data:{
            type: Object,
            default: () => {}
        },
        infoText:{
            type:String,
            default:""
        }
    },
    data(){
        return{
            isMobile: false,
            executionTimeCounter: 0,
            enableGoToQuestions: false,
            executionInterval: null,
            viewedPages:[],
            showModal: false,
            executionData:{
                pagesViewed: 0,
                clicks:0,
                secondsElapsed: 0,
                finalUrl:'',
                mobile: false
            }
        }
    },
    methods:{
        openInformationModal(){
            this.showModal = !this.showModal
        },
        executionTimeFinished(){
            let vm = this;
            Swal.fire({
                text:vm.$t('watux_quanti_finish_message'),
                icon:"info",
                showCancelButton: false,
                showConfirmButton: true,
                allowOutsideClick: false
            })
           this.goToQuestions()
        },
        goToQuestions(){
            if(!this.enableGoToQuestions){
                let vm = this;
                Swal.fire({
                    text:vm.$t('watux_quanti_not_finish_message'),
                    icon:"info",
                    showCancelButton: false,
                    showConfirmButton: true,
                    allowOutsideClick: false
                })
                return
            }
            clearInterval(this.executionInterval)
            this.$emit('finished', this.executionData)
        },
        initExecutionTime(){
            this.executionTimeCounter = this.data.minTime
        },
        initPostMessagesListener(){
            let vm = this
            window.addEventListener("message", (event) => { vm.receivedMessage(event) }, false);
        },
        receivedMessage(event){
            if(event.data.type == "MOUSE_PRESS_OR_RELEASE") this.executionData.clicks ++
            if(event.data.type == "INITIAL_LOAD") this.startExecutionTime()
            if(event.data.type == "PRESENTED_NODE_CHANGED") this.addLoadedPage(event)
        },
        startExecutionTime(){
            let vm = this
            this.executionInterval = setInterval(function() {
                if(vm.executionData.secondsElapsed == vm.data.maxTime) return vm.executionTimeFinished()
                if(vm.executionTimeCounter > 0) vm.executionTimeCounter = vm.executionTimeCounter - 1
                if(vm.executionTimeCounter == 0 && !vm.enableGoToQuestions) vm.enableGoToQuestions = true
                vm.executionData.secondsElapsed = vm.executionData.secondsElapsed + 1
            }, 1000);
        },
        addLoadedPage(){
            const nodeId = event.data.data.presentedNodeId
            const nodeModifiedId = nodeId.replace(":","-")
            if(!this.viewedPages.includes(nodeId)){
                this.viewedPages.push(nodeId)
                this.executionData.finalUrl = this.getUrlAndReplaceNodeId(nodeId)
                this.executionData.pagesViewed ++
                const succesUrl = this.getSuccessUrlByNodeId(nodeId)
                if(succesUrl != null && succesUrl != undefined) {
                    this.enableGoToQuestions = true
                    this.executionData.finalUrl = succesUrl.href
                }
            } 
        },
        getSuccessUrlByNodeId(nodeId){
            const objectUrls = this.getObjectURLs()
            const nodeIdLine = nodeId.replace(":","-")
            for(let url of objectUrls) {
                const finalNodeId = url.searchParams.get('node-id')
                if(finalNodeId == nodeId || finalNodeId == nodeIdLine) return url
            }
            return null
        },
        getUrlAndReplaceNodeId(nodeId){
            const url = this.getObjectURLs()[0]
            url.searchParams.set('node-id', nodeId)
            return url.href
        },
        getObjectURLs(){
            if(this.isMobile) return this.data.mobileSuccessUrls.map(o => new URL(o.url))
            else return this.data.desktopSuccessUrls.map(o => new URL(o.url))             
        },
        setPlatformType(){
            this.isMobile = window.innerWidth <=769
        }
    },
    mounted(){
        this.setPlatformType()
        this.initExecutionTime()
        this.initPostMessagesListener()
        this.openInformationModal()
    }
}

</script>
    
    
<style lang="scss">
    .WatUXQuanti{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: white;
        &--Modal{
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left:0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0,0,0,0.5);
        }
        &--ModalHeader{
            border-bottom: 1px solid #e0e0e0;
            padding:1em;
            display: flex;
            justify-content: space-between;
            width: 100%;
            h1{
                font-family: 'Lato';
                font-size: 20px;
            }
            i{
                font-size: 18px;
                cursor: pointer;
                &:hover{
                    color: red;
                }
            }
        }
        &--ModalContent{
            display: flex;
            flex-direction: column;
            position: relative;
            background: white;
            width: fit-content;
            max-width: 600px;
            box-shadow: 0 10px 20px rgba(0,0,0,0.2);
            border-radius: 0.5em;
            align-items: center;
            padding-bottom: 1em;
            p{
                padding: 1em 1em 2em 1em;
                text-align: justify;
            }
            button{
                font-size: 13px!important;
            }
        }
        &--Info{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 30px;
            padding: 15px 0;
        }
        &--Instructions{
            background-color: #cce5ff;
            border: 1px solid #b8daff;
            border-radius: 3px;
            padding: 12px 20px;
            display: flex;
            gap: 5px;
            align-items: baseline;
            cursor: pointer;
            &:hover{
                border-color: #004085;
            }
            i{
                color: #004085;
            }
        }
        button{
            background: var(--button-color);
            font-family: "Bariol";
            font-weight: 600;
            font-size: 16px;
            border-radius: 3px;
            border: none;
            padding: 0.8rem 1rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: white;
            width: fit-content;
            cursor: pointer;
        }
    }
</style>
          